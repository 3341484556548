.app:global(.dark) {
	/* https://www.colourlovers.com/palette/72905/iam_approachingnight */
	--color-text: #fff;
	--color-primary: #cfff19;
	--color-secondary: #e0dbbd;
	--color-error: #eb1b3b;
	--color-background: #122125;
	--color-login-button: var(--color-text);

	/* Chat Client */
	--chat-bubble-meta: rgba(0, 0, 0, 0.45);
	--chat-compose-input-background: #fff;
	--chat-compose-input-border: #fff;
	--chat-icon-disabled: #aaa;
	--chat-icon-error: #eb1b3b;
	--chat-panel-header-background: #003048;
	--chat-panel-footer-background: #003048;
	--chat-primary-strong: #fff;
	--chat-primary-title: #525252;
	--chat-secondary: rgba(255, 255, 255, 0.8);
	--chat-shadow-rgb: 0, 0, 0;
	--chat-incoming-background: #fff;
	--chat-incoming-text: #000;
	--chat-outgoing-background: #cfff19;
	--chat-outgoing-text: #122125;
	--chat-system-background: #003048;
	--chat-system-text: #fff;
	--chat-bubble-link: #039be5;
	--chat-bubble-deleted: #999;
	--chat-from-text-1: #fe7312;
	--chat-from-text-2: #d85395;
	--chat-from-text-3: #dce045;
	--chat-from-text-4: #75a495;
	--chat-from-text-5: #ffd7e2;
	--chat-from-text-6: #ffeb4a;
	--chat-from-text-7: #ff4db9;
	--chat-from-text-8: #eb0450;
	--chat-from-text-9: #7a0406;
	--chat-from-text-10: #039be5;
}

.app:global(.light1) {
	/* https://www.colourlovers.com/palette/72905/iam_approachingnight */
	--color-text: #000;
	--color-primary: #d6254d;
	--color-secondary: #ff774a;
	--color-error: #d6254d;
	--color-background: #fdeba9;
	--color-login-input-background: rgba(255, 255, 255, 0.7);
	--color-login-button: var(--color-text);

	/* Chat Client */
	--chat-text-color: #000;
	--chat-bubble-meta: rgba(0, 0, 0, 0.45);
	--chat-compose-input-background: #fff;
	--chat-compose-input-border: #fff;
	--chat-icon-disabled: #aaa;
	--chat-icon-error: #d6254d;
	--chat-panel-header-background: #ff9036;
	--chat-panel-footer-background: #ff9036;
	--chat-primary-strong: #000;
	--chat-primary-title: #525252;
	--chat-secondary: rgba(255, 255, 255, 0.8);
	--chat-shadow-rgb: 0, 0, 0;
	--chat-incoming-background: #f6d76b;
	--chat-incoming-text: #000;
	--chat-outgoing-background: #ff5475;
	--chat-outgoing-text: #000;
	--chat-bubble-link: #039be5;
	--chat-bubble-deleted: #999;
	--chat-list-item-hover-background: rgba(255, 255, 255, 0.3);
	--chat-list-border: rgba(0, 0, 0, 0.5);
	--chat-list-selected-background: rgba(255, 255, 255, 0.2);
	--chat-icon: var(--color-primary);
	--chat-unread-marker-background: var(--color-primary);
}

.app:global(.light2) {
	/* https://www.colourlovers.com/palette/72905/iam_approachingnight */
	--color-text: #505052;
	--color-primary: #7254f5;
	--color-error: #d6254d;
	--color-background: #e8e5f4;
	--color-login-input-background: #efecfc;
	--color-login-button: var(--color-primary);

	/* Chat Client */
	--chat-text-color: #000;
	--chat-background-image: linear-gradient(#fff, #fff);
	--chat-bubble-meta: #bbb8cd;
	--chat-compose-input-background: #efecfb;
	--chat-compose-input-border: #efecfb;
	--chat-compose-input-text: var(--color-primary);
	--chat-compose-input-placeholder: var(--color-primary);
	--chat-icon-disabled: #aaa;
	--chat-icon-error: #d6254d;
	--chat-panel-header-background: #fcfbfc;
	--chat-panel-footer-background: #fff;
	--chat-primary-strong: #505052;
	--chat-primary-title: #525252;
	--chat-secondary: #9f9ead;
	--chat-secondary-stronger: #505052;
	--chat-shadow-rgb: 200, 200, 200;
	--chat-incoming-background: #f6f6f6;
	--chat-incoming-text: #918fa3;
	--chat-outgoing-background: var(--color-primary);
	--chat-outgoing-text: #eee4ff;
	--chat-system-background: #fff9ef;
	--chat-system-text: #cf9f66;
	--chat-bubble-link: #039be5;
	--chat-bubble-deleted: #999;
	--chat-list-background: #fcfbfc;
	--chat-list-item-hover-background: rgba(0, 0, 0, 0.2);
	--chat-list-border: #f1f0f7;
	--chat-list-selected-background: #fff9ef;
	--chat-icon: var(--color-primary);
	--chat-unread-marker-background: var(--color-primary);
	--chat-from-text-1: #fe7312;
	--chat-from-text-2: #d85395;
	--chat-from-text-3: #039be5;
	--chat-from-text-4: #75a495;
	--chat-from-text-5: #ffd7e2;
	--chat-from-text-6: #ffeb4a;
	--chat-from-text-7: #ff4db9;
	--chat-from-text-8: #eb0450;
	--chat-from-text-9: #7a0406;
	--chat-from-text-10: #039be5;
}

.app {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: var(--color-background);
}

.singleChatWrapper {
	height: 100%;
	width: 100%;
	max-width: 960px;
	margin: 0 auto;
}

.chatListWrapper {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
}

.ChatWindow.mobile.chat {
	transform: translate3d(-50%, 0, 0);
}

.chatListWrapper__list {
	height: 100%;
	width: 35%;
	flex-grow: 0;
	flex-shrink: 0;
}

.chatListWrapper__chat {
	width: 100%;
	height: 100%;
	flex-grow: 1;
}

@media screen and (min-width: 960px) {
	.singleChatWrapper {
		padding: 30px 0;
	}
}

@media screen and (max-width: 800px) {
	.chatListWrapper {
		width: 200%;
		transform: translate3d(0);
		transition: transform 0.3s;
	}

	.chatListWrapper__list,
	.chatListWrapper__chat {
		width: 50%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}
