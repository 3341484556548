* {
	box-sizing: border-box;
}

*:focus {
	outline: none;
}

a {
	text-decoration: none;
	color: var(--color-primary, #04d9ff);
}

html,
body {
	overscroll-behavior: none;
}

html {
	-webkit-tap-highlight-color: transparent;
}

html,
body,
#root {
	height: 100%;
	width: 100%;
	overflow: hidden;
}

body {
	margin: 0;
	/*-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;*/
	background-color: #0e0e10;
	color: #fff;
	font-size: 16px;
}

body,
button,
input {
	font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
}

input,
select {
	font-size: 16px;
}

textarea,
input[type="text"],
input[type="submit"] {
	-webkit-appearance: none;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
	margin: 0 !important;
	text-align: center;
}
