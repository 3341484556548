.page {
	width: 100%;
	height: 100%;
	color: var(--color-text);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	text-align: center;
}

.logo {
	color: var(--color-primary);
	font-size: 30px;
	font-weight: bold;
}

.subtitle {
	margin: 0.5em 0;
	padding: 0 1em;
}

.form {
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.form__input {
	font-size: 20px;
	line-height: 20px;
	border: 0;
	border-radius: 50px;
	padding: 10px 20px;
	margin-top: 10px;
	width: 300px;
	background-color: var(--color-login-input-background);
	transition: opacity 0.2s;
}

.loading .form__input {
	opacity: 0.5;
	pointer-events: none;
}

.form__submit {
	border-radius: 50px;
	font-size: 20px;
	line-height: 20px;
	padding: 8px 30px;
	background-color: transparent;
	border: 1px solid var(--color-login-button);
	color: var(--color-login-button);
	transition: background-color 0.5s, color 0.5s;
	margin-top: 10px;
	cursor: pointer;
	user-select: none;
}

.form__submit:hover {
	background-color: var(--color-login-button);
	color: var(--color-background);
}

.form__submit.disabled {
	pointer-events: none;
	opacity: 0.5;
}

.loading .form__submit {
	pointer-events: none;
}

.form__submit__spinner {
	display: block;
	height: 1em;
	width: 1em;
	vertical-align: top;
	animation: spin linear infinite 3s;
}

.form__error {
	margin-top: 10px;
	color: var(--color-error);
	height: 1em;
	line-height: 1em;
}

@keyframes spin {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}
